"use client";

import { cva } from "class-variance-authority";
import { differenceInSeconds, format, setHours, setMinutes } from "date-fns";
import { ja, vi } from "date-fns/locale";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {
  DayModifiers,
  DayPicker,
  Matcher,
  ModifiersStyles,
} from "react-day-picker";
import { useTranslation } from "react-i18next";
import { CloseCircleFill } from "~/core/ui/FillIcons";
import IconWrapper from "~/core/ui/IconWrapper";
import { Input, InputVariantProps } from "~/core/ui/Input";
import { cn } from "~/core/ui/utils";
import { REGEX_MINUTE_SECONDS } from "~/lib/features/calendar/utilities/enum.cva";
import {
  getTimeSelectOptions,
  getTimeWithDisableAfterSelectOptions,
} from "~/lib/features/calendar/utilities/helper-schedule-interview";
import { ISelectOption } from "../@types/global";
import { defaultFormatDate } from "../utilities/format-date";
import { NativeSelect } from "./NativeSelect";

const rightIconVariants = cva(
  "absolute top-0 bottom-0 text-gray-400 dark:text-gray-600 inline-flex items-center justify-center right-0",
  {
    variants: {
      size: {
        md: "w-[18px] right-3",
        sm: "w-4 right-[10px]",
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
);

const DEFAULT_DATE_PICKER_CONFIG = {
  defaultOpen: false,
  onClick: undefined,
  onChange: undefined,
  onClear: undefined,
  defaultValue: undefined,
  value: undefined,
  disabled: undefined,
  className: "",
  showOutsideDays: true,
  sideOffset: "pt-1",
  fromYear: 2000,
  toYear: 2035,
  showClearIndicator: true,
  id: 1,
  showSelectTime: false,
  isDisableTimeAfter: false,
};

const getClassNameDatePicker = ({
  mode = "single",
}: {
  mode: "single" | "multiple" | "range";
}) => {
  return {
    root: "cursor-pointer",
    multiple_months: "",
    with_weeknumber: "",
    vhidden: "hidden",
    button_reset: "",
    button: "",
    caption: "h-10 flex justify-center relative items-center",
    caption_start: "",
    caption_end: "",
    caption_between: "",
    caption_label:
      "text-base text-gray-900 dark:text-gray-200 font-medium flex items-center",
    caption_dropdowns:
      mode === "range"
        ? "relative flex-1 flex items-center justify-center"
        : "relative flex-1 flex items-center ml-3",
    dropdown:
      "absolute appearance-none cursor-pointer z-[2] top-0 bottom-0 left-0 w-full m-0 p-0 cursor-inherit opacity-0 border-none bg-transparent",
    dropdown_month: "relative",
    dropdown_year: "relative ml-3",
    dropdown_icon: "",
    months: `flex ${mode !== "range" ? "flex-col space-y-4" : "space-x-4"} `,
    month: "",
    table: "w-full border-collapse",
    tbody: "",
    tfoot: "",
    head: "",
    head_row: "flex justify-center items-center",
    head_cell:
      "rounded h-10 w-10 p-0 text-gray-700 dark:text-gray-300 text-sm flex items-center justify-center",
    nav: "flex items-center",
    nav_button: "h-8 w-8 bg-transparent p-0 flex items-center justify-center",
    nav_button_previous: mode === "range" ? "absolute left-0" : "",
    nav_button_next: mode === "range" ? "absolute right-0" : "",
    nav_icon: "",
    row: "flex justify-center items-center w-full mt-1",
    weeknumber: "",
    cell: `rounded text-center text-sm w-8 h-8 m-1 p-0 relative [&:has([aria-selected])]:bg-gray-50 dark:[&:has([aria-selected])]:bg-gray-700 first:[&:has([aria-selected])]:rounded-l last:[&:has([aria-selected])]:rounded-r focus-within:relative focus-within:z-20 ${
      mode === "range" ? "[&:has([aria-selected])]:rounded-none" : ""
    }`,
    day: "rounded h-8 w-8 p-0 text-gray-700 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-300 dark:hover:bg-gray-700",
    day_outside: "!text-gray-400 !dark:text-gray-600",
    day_selected:
      "!bg-primary-400 !text-white hover:bg-primary-400 hover:text-white dark:bg-primary-400 dark:text-white dark:hover:bg-primary-400 dark:hover:text-white",
    day_disabled: "opacity-50 pointer-events-none",
    day_hidden: "invisible",
    day_range_start: "",
    day_range_end: "",
    day_range_middle:
      "aria-selected:bg-gray-50 aria-selected:text-gray-700 dark:aria-selected:bg-gray-700 dark:aria-selected:text-gray-300 aria-selected:rounded-none",
    day_today: "font-bold text-primary-400",
  };
};

type SingleDatePickerSizeProps = "md" | "sm";

interface SingleDatePickerProps {
  config?: {
    defaultOpen?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    onChange?: (value: Date | undefined) => void;
    onClear?: () => void;
    defaultValue?: Date | undefined;
    value?: Date | undefined;
    className?: string;
    showOutsideDays?: boolean;
    sideOffset?: number;
    fromYear?: number;
    toYear?: number;
    disabled?: Matcher | Matcher[] | undefined;
    showClearIndicator?: boolean;
    id?: number;
    isDisableTimeAfter?: boolean;
    showSelectTime?: boolean;
  };
  onCustomOnchange?: (onChangeDay: (day: Date) => void, d?: Date) => void;
  modifiersStyles?: ModifiersStyles;
  modifiers?: DayModifiers;
  isDisabled?: boolean;
  className?: string;
  size?: SingleDatePickerSizeProps;
  destructive?: boolean;
  placeholder?: string;
  autoUpdateValue?: boolean;
  useOnlyFunction?: boolean;
  variant?: InputVariantProps;
  locale?: string;
}

const SingleDatePicker = (props: SingleDatePickerProps) => {
  const {
    config = DEFAULT_DATE_PICKER_CONFIG,

    isDisabled = false,
    className = "",
    size = "md",
    destructive = false,
    placeholder = "",
    useOnlyFunction = false,
    variant = "default",
    modifiersStyles,
    modifiers,
    autoUpdateValue = false,
    onCustomOnchange,
    locale = "en",
  } = props;
  const mergedConfig = {
    ...DEFAULT_DATE_PICKER_CONFIG,
    ...config,
  };
  const { t } = useTranslation();
  const [open, onOpenChange] = useState(mergedConfig.defaultOpen || false);
  const [selectedDay, setSelectedDay] = useState<Date | undefined>();
  const [timeValue, setTimeValue] = useState<string>("09:00");
  const timeOptions = useMemo<Array<ISelectOption>>(
    () =>
      config?.isDisableTimeAfter
        ? getTimeWithDisableAfterSelectOptions({ selectedDate: selectedDay })
        : getTimeSelectOptions({}),
    [selectedDay]
  );

  const refSinglePicker = useOnclickOutside(
    () => {
      onOpenChange(false);
    },
    {
      ignoreClass: `my-ignore-single-picker-${config.id}`,
    }
  );
  const handleTimeChange = (e: ISelectOption) => {
    const time = e.value;
    if (!selectedDay) {
      setTimeValue(time);
      return;
    }
    const [hours, minutes] = time.split(":").map((str) => parseInt(str, 10));
    const newSelectedDate = setHours(setMinutes(selectedDay, minutes), hours);
    setSelectedDay(newSelectedDate);
    config.onChange && config.onChange(newSelectedDate);
    setTimeValue(time);
  };

  useEffect(() => {
    if (!selectedDay && config.value) {
      setSelectedDay(config?.value);
      setTimeValue(format(config.value, "HH:mm"));
    }
  }, []);

  useEffect(() => {
    if (autoUpdateValue && config.value) {
      setSelectedDay(config.value);
      setTimeValue(format(config.value, "HH:mm"));
    }
  }, [config?.value]);

  const renderDatePicker = () => {
    return (
      <>
        <DayPicker
          locale={locale === "ja" ? ja : locale === "vi" ? vi : undefined}
          mode="single"
          captionLayout="dropdown-buttons"
          modifiers={modifiers}
          modifiersStyles={modifiersStyles}
          fromYear={mergedConfig.fromYear}
          toYear={mergedConfig.toYear}
          showOutsideDays={mergedConfig.showOutsideDays}
          disabled={mergedConfig.disabled}
          defaultMonth={config?.value}
          selected={selectedDay}
          onSelect={(e) => {
            onOpenChange(false);
            if (!config.showClearIndicator && !e) return;
            if (onCustomOnchange) {
              onCustomOnchange(setSelectedDay, e);
            } else {
              if (e) {
                const [hours, minutes] = timeValue
                  .split(":")
                  .map((str) => parseInt(str, 10));
                const newSelectedDate = setHours(setMinutes(e, minutes), hours);
                setSelectedDay(newSelectedDate);
                if (config.onChange) {
                  config.onChange(newSelectedDate);
                }
              }
            }
          }}
          classNames={getClassNameDatePicker({ mode: "single" })}
          components={{
            IconLeft: () => <IconWrapper name="ChevronLeft" size={18} />,
            IconRight: () => <IconWrapper name="ChevronRight" size={18} />,
            IconDropdown: () => (
              <div className="ml-1">
                <IconWrapper name="ChevronDown" size={18} />
              </div>
            ),
          }}
        />
        {config?.showSelectTime && (
          <form style={{ marginBlockEnd: "1em" }}>
            <div className="h-[1px] w-full bg-gray-50" />
            <div className="flex items-center justify-between px-[18px] pb-2 pt-1">
              <div className="flex items-center text-sm font-medium">
                <IconWrapper size={16} name="Timer" className="mr-1" />
                {t("label:time")}
              </div>
              <NativeSelect
                isDisabled={!selectedDay}
                classNameOverride={{
                  menu: "!min-w-[124px]",
                  input: "text-gray-900",
                  loadingMessage: `${t("label:loading")}`,
                  noOptionsMessage: `${t("label:noOptions")}`,
                }}
                formatCreateLabel={(value) => value}
                onChange={(e) => handleTimeChange(e as ISelectOption)}
                size="sm"
                value={{
                  value: timeValue,
                  supportingObj: {
                    name: timeValue,
                  },
                }}
                options={timeOptions}
                variant="ghost"
                showDropdownIndicator={false}
                isClearable={false}
                creatable
                isValidNewOption={(input) => {
                  return REGEX_MINUTE_SECONDS.test(input || "");
                }}
              />
            </div>
          </form>
        )}
      </>
    );
  };

  if (useOnlyFunction) {
    return renderDatePicker();
  }

  return (
    <div className="relative">
      <div className={`my-ignore-single-picker-${config.id} group relative`}>
        <div onClick={() => onOpenChange(true)}>
          <Input
            isFocus={open}
            variant={variant}
            value={selectedDay ? defaultFormatDate(selectedDay) : ""}
            size="sm"
            isDisabled={isDisabled}
            placeholder={placeholder}
            destructive={destructive}
            className={className}
          />
        </div>

        <div
          className={cn(
            rightIconVariants({
              size,
            })
          )}
          onClick={(e) => {
            e.preventDefault();
            if (selectedDay && config.showClearIndicator) {
              setSelectedDay(undefined);
              if (config.onClear) {
                config.onClear();
              }
            } else {
              // incase calendar icon is showing, should be open modal for click event.
              onOpenChange(true);
            }
          }}
        >
          <div
            className={cn(
              variant === "ghost"
                ? "hidden group-focus-within:flex group-focus-within:items-center"
                : ""
            )}
          >
            <div className="hidden cursor-pointer group-hover:block">
              {selectedDay && config.showClearIndicator ? (
                <CloseCircleFill className="fill-gray-400 dark:fill-gray-500" />
              ) : (
                <IconWrapper size={16} name="Calendar" />
              )}
            </div>
            <div className="block group-hover:hidden">
              <IconWrapper size={16} name="Calendar" />
            </div>
          </div>
        </div>
      </div>

      {open ? (
        <div
          ref={refSinglePicker}
          className={cn(
            "my-ignore-action absolute right-0 top-full z-50",
            config.className || "min-w-[296px]",
            mergedConfig.sideOffset || "pt-1"
          )}
        >
          <div className="rounded bg-white p-2 shadow-ats outline-none dark:bg-gray-900 dark:shadow-dark-ats">
            {renderDatePicker()}
          </div>
        </div>
      ) : null}
    </div>
  );
};

SingleDatePicker.displayName = "SingleDatePicker";

export { SingleDatePicker };
export type { SingleDatePickerProps, SingleDatePickerSizeProps };
